<template>
  <div class="category-news__wrapper">
    <a-lazy-hydrate when-visible>
      <a-visibility hide :to="$breakpoint.desktopSm">
        <a-breadcrumbs
          :settings="breadcrumbsSettings"
          :prettify-names="false"
          class="click-spam-left-padding"
        />
      </a-visibility>
    </a-lazy-hydrate>
    <a-visibility hide :on="[$breakpoint.mobile]">
      <h1 class="category-news__title click-spam-left-padding title">
        {{ categoryName }}
      </h1>
    </a-visibility>
    <a-card class="category-news__card skip-mobile-paddings">
      <a-visibility show :on="[$breakpoint.mobile]">
        <h1 class="category-news__title click-spam-left-padding">
          {{ categoryName }}
        </h1>
      </a-visibility>
      <a-visibility show :on="[$breakpoint.mobile]">
        <a-join-telegram class="category-news__join-telegram" />
      </a-visibility>
      <!-- TODO animation requirements are to be discussed with the client -->
      <!--      <a-in-progress :in-progress="feedRefreshInProgress">-->
      <a-article-list :articles="articles" class="category-news__article-list">
        <template v-slot="{ article, position }">
          <!--            <a-in-progress-->
          <!--              :in-progress="slotRefreshInProgress[position]"-->
          <!--              :align-spinner="SPINNER_ALIGNMENT.CENTER"-->
          <!--            >-->
          <a-lazy-hydrate when-visible>
            <a-article-slot
              v-bind="article"
              v-observe-visibility="getArticleVisibilityOptions(position)"
              :hide-category="!article.company"
              :image-viewport-optimization="isInViewport(position)"
              class="category-news__article"
            />
          </a-lazy-hydrate>
          <!--            </a-in-progress>-->
        </template>

        <template v-slot:banner="{ position }">
          <a-video-banner
            v-if="isVideoBannerVisible(position)"
            :video-banner-type="VIDEO_BANNER_TYPE.CATEGORY"
            class="category-news__video-banner"
          />
          <a-banner
            v-else-if="isBannerVisible(position)"
            :banner-settings="getBannerSettings(position)"
            class="category-news__banner"
            use-random-banner-id
          />
        </template>

        <template v-slot:video="{ position }">
          <a-video-slot
            v-if="isVideoVisible(position)"
            :video-settings="videoSettings"
            class="category-news__video"
          />
        </template>
      </a-article-list>
      <!--      </a-in-progress>-->

      <a-see-more-less
        :page="pagination.page"
        :pages-count="pagesCount"
        slot="bottom-section"
        :is-expanded="false"
        :in-progress="inProgress"
        class="category-news__see-more-less"
        @input="getNextArticleChunk"
      />
    </a-card>

    <a-url-pagination
      :pagination.sync="pagination"
      :item-count="articles.length"
      :initial-page="initialPage"
      :item-selector="ARTICLE_SLOT_SELECTOR"
      :pages-count="pagesCount"
      :default-take-fn="getDefaultTake"
      :non-paginated-url="canonicalUrlWithoutPagination"
    />

    <a-pagination-links-for-google
      :page="pagination.page"
      :pages-count="pagesCount"
      :non-paginated-url="canonicalUrlWithoutPagination"
    />

    <a-refresh-listener
      :articles.sync="articles"
      :feed-refresh-in-progress.sync="feedRefreshInProgress"
      :slot-refresh-in-progress.sync="slotRefreshInProgress"
      :fetch-data-fn="fetchLoadedArticles"
      :target-category-slug="categorySlug"
      :settings="$options.consts.AUTO_REFRESH_SETTINGS.CATEGORY_PAGE"
      @feed-refreshed="$_disqusCommentCount_updateCommentCounts"
    />

    <a-sticky-banner
      v-if="isStickyToBottomBannerVisible"
      :sticky-position="STICKY_BANNER_POSITION.BOTTOM"
      :banner-settings="BANNER_SETTINGS.CATEGORY.STICKY_TO_BOTTOM"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '@/utils/mixins'
import ACard from 'shared/ACard'
import AArticleList from 'shared/AArticleList'
import AArticleSlot from 'shared/AArticleSlot'
import ABanner from 'shared/ABanner'
import {
  BANNER_SETTINGS,
  getInsideListBannerSettingsKey
} from 'enums/banners/banner-settings'
import { aspectRatios } from 'enums/aspectRatios'
import AUrlPagination from 'shared/AUrlPagination'
import ARefreshListener from 'shared/ARefreshListener'
import { SPINNER_ALIGNMENT } from 'shared/AInProgress'
import { addEndingSlash } from '@fmpedia/helpers'
import { createPrefilledArray, getPageNumberFromRoute } from '@/plugins/helper'
import { generateCategoryPath } from '@/utils/helpers/url-generators'
import { ROUTE_NAME } from 'enums/routes'
import {
  findMigratedCategorySlug,
  MIGRATED_CATEGORY_SLUG
} from 'enums/categories'
import {
  handlePaginationOfCanonicalLink,
  redirectIfNonCanonicalUrl
} from '@/utils/helpers/canonical-links'
import { STICKY_BANNER_POSITION } from 'enums/banners/sticky-banner'
import AStickyBanner from 'shared/AStickyBanner'
import { OBSERVER_CALLBACK_DEFAULT_THROTTLE } from 'enums/mutation-observer'
import { VIDEO_BANNER_TYPE } from 'enums/video-banner'
import { PAGE_SCHEME_TYPE } from 'enums/pageSchemes'
import { DISQUS_SOURCE } from 'shared/AActionIcon/_shared/CommentCount/enums'
import APaginationLinksForGoogle from 'shared/APaginationLinksForGoogle'
import { AUTO_REFRESH_SETTINGS } from 'enums/article-auto-refresh'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const DEFAULT_TAKE = 25
const VIDEO_BANNER_POSITION = 2
const BANNER_POSITION = 7
const BANNERS_COUNT = 5
const VIDEO_POSITION = 9
const ARTICLE_SLOT_SELECTOR = '.category-news__article'
const ARTICLE_POSITION_TO_DISPLAY_STICKY_BANNER = 3
const ARTICLE_SLOT_COUNT_IN_VIEWPORT = 2

export default {
  name: 'CategoryPage',
  scrollToTop: true,
  components: {
    AStickyBanner,
    AVideoBanner: () => import('shared/AVideoBanner'),
    ACard,
    AArticleList,
    AArticleSlot,
    ABanner,
    AVideoSlot: hydrateWhenVisible(() => import('shared/AVideoSlot'), {
      props: ['video-settings']
    }),
    ASeeMoreLess: hydrateWhenVisible(() => import('shared/ASeeMoreLess'), {
      props: ['page', 'pages-count', 'is-expanded', 'in-progress']
    }),
    AJoinTelegram: hydrateWhenVisible(() => import('shared/AJoinTelegram')),
    AUrlPagination,
    ARefreshListener,
    // AInProgress,
    APaginationLinksForGoogle
  },
  mixins: [
    mixins.setPageLoaded,
    mixins.urlGenerators,
    mixins.articleDataHandler,
    mixins.bannerRotation,
    mixins.ebookDataFetchMixinFactory(),
    mixins.disqusCommentCountMixinFactory({ source: DISQUS_SOURCE.FL }),
    hydrationHelpers
  ],
  consts: { AUTO_REFRESH_SETTINGS },
  head() {
    return this.$generateMetaTags({
      titleParam: this.categoryName,
      descriptionParam: this.categoryName,
      canonicalUrlGenerator: () => this.canonicalUrl,
      schemes: [
        { type: PAGE_SCHEME_TYPE.ORGANIZATION },
        { type: PAGE_SCHEME_TYPE.WEB_SITE },
        ...this.$helper.generateVideoSchemasFromResponse([
          this.videoSettings?.Video,
          ...this.articles.map(article => article.video)
        ])
      ]
    })
  },
  async asyncData(ctx) {
    try {
      const dispatch = ctx.store.dispatch
      const initialPage = getPageNumberFromRoute(ctx.route)
      const { category: categorySlug } = ctx.route.params

      const pagination = {
        defaultTake: DEFAULT_TAKE,
        page: initialPage
      }

      const payload = {
        categorySlug,
        ...pagination
      }

      const requests = [
        dispatch('news/requestNewsByCategory', payload),
        dispatch('categories/requestCategoryVideo', { categorySlug })
      ]

      const [
        {
          articles,
          categoryName,
          categorySlug: categorySlugFromBackend,
          pagesCount
        },
        videoSettings
      ] = await Promise.all(requests)

      const categoryUrl = generateCategoryPath({
        slug: categorySlugFromBackend
      })
      const isMigratedCategory = !!findMigratedCategorySlug(categorySlug)
      const handler = isMigratedCategory ? v => v : addEndingSlash

      const canonicalUrlWithoutPagination = handler(
        `${ctx.$env.DOMAIN_URL}${categoryUrl}`
      )
      const canonicalUrl = handlePaginationOfCanonicalLink(
        canonicalUrlWithoutPagination,
        ctx.route.path
      )

      if (await redirectIfNonCanonicalUrl(ctx, canonicalUrl)) return

      return {
        articles,
        videoSettings,
        categoryName,
        categorySlug,
        initialPage,
        pagination,
        pagesCount,
        feedRefreshInProgress: false,
        slotRefreshInProgress: createPrefilledArray(articles.length, false)
      }
    } catch (err) {
      ctx.app.$errorHandler(err, ctx, {
        showErrorPage: true,
        componentName: 'CategoryPage'
      })
    }
  },
  data() {
    const initialPage = getPageNumberFromRoute(this.$route)

    return {
      BANNER_SETTINGS,
      ARTICLE_SLOT_SELECTOR,
      SPINNER_ALIGNMENT,
      aspectRatios,
      pagesCount: 0,
      initialPage,
      pagination: {
        defaultTake: DEFAULT_TAKE,
        page: initialPage
      },
      inProgress: false,
      articles: [],
      videoSettings: null,
      categoryName: '',
      categorySlug: '',
      feedRefreshInProgress: false,
      slotRefreshInProgress: [],
      STICKY_BANNER_POSITION,
      articleVisibilityOptions: {
        callback: this.setArticleViewState,
        throttle: OBSERVER_CALLBACK_DEFAULT_THROTTLE
      },
      isArticleSlotInsideOrAboveViewport: false,
      VIDEO_BANNER_TYPE
    }
  },
  computed: {
    canonicalUrlWithoutPagination() {
      const categoryUrl = this.generateCategoryPath({
        slug: this.categorySlug
      })

      return `${this.$env.DOMAIN_URL}${categoryUrl}`
    },
    canonicalUrl() {
      return handlePaginationOfCanonicalLink(
        this.canonicalUrlWithoutPagination,
        this.$route.path
      )
    },
    breadcrumbsSettings() {
      return {
        [ROUTE_NAME.CATEGORY]: { nameHandler: () => this.categoryName }
      }
    },
    isStickyToBottomBannerVisible() {
      return (
        this.isArticleSlotInsideOrAboveViewport &&
        ![MIGRATED_CATEGORY_SLUG.TECHNICAL_ANALYSIS].includes(
          this.$route.params.category
        )
      )
    }
  },
  methods: {
    ...mapActions({
      requestNewsByCategory: 'news/requestNewsByCategory'
    }),
    setArticleViewState(isVisible, entry) {
      this.isArticleSlotInsideOrAboveViewport = this.$_hydrationHelpers_isEntryTargetInsideOrAboveViewport(
        isVisible,
        entry
      )
    },
    getArticleVisibilityOptions(index) {
      const position = index + 1

      if (position === ARTICLE_POSITION_TO_DISPLAY_STICKY_BANNER) {
        return this.articleVisibilityOptions
      }

      return null
    },
    async getCategoryNews() {
      try {
        this.inProgress = true
        const payload = {
          categorySlug: this.categorySlug,
          ...this.pagination
        }

        const {
          articles,
          categoryName,
          pagesCount
        } = await this.requestNewsByCategory(payload)

        this.articles = [...this.articles, ...articles]
        this.slotRefreshInProgress = createPrefilledArray(
          this.articles.length,
          false
        )
        this.categoryName = categoryName
        this.pagesCount = pagesCount
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    },
    getNextArticleChunk() {
      this.pagination.page++
      this.getCategoryNews()
    },
    isVideoBannerVisible(index) {
      const position = index + 1
      return position === VIDEO_BANNER_POSITION
    },
    /**
     * More details regarding the rotation of banners on this page here:
     * https://docs.google.com/document/d/1kDCjTGJJkBU-EGjYPcno1ZrRfVFx4bTk/edit#heading=h.tyjcwt
     * The spec is for All News, but the rule is applicable to this page as well
     */
    isBannerVisible(index) {
      return this.$_bannerRotation_isBannerVisible({
        index,
        take: DEFAULT_TAKE,
        bannerPosition: BANNER_POSITION
      })
    },
    getBannerSettings(index) {
      const bannerNumber = this.$_bannerRotation_getBannerNumber({
        index,
        take: DEFAULT_TAKE,
        bannerPosition: BANNER_POSITION,
        bannerCount: BANNERS_COUNT
      })

      return BANNER_SETTINGS.CATEGORY[
        getInsideListBannerSettingsKey(bannerNumber)
      ]
    },
    isVideoVisible(index) {
      const position = index + 1
      return this.videoSettings && position === VIDEO_POSITION
    },
    async fetchLoadedArticles() {
      const payload = {
        categorySlug: this.categorySlug
      }

      const articleChunks = await this.$_articleDataHandler_fetchLoadedArticles(
        {
          action: this.requestNewsByCategory,
          initialPage: this.initialPage,
          defaultTakeFn: this.getDefaultTake,
          length: this.articles.length,
          payload: payload
        }
      )
      return articleChunks.reduce(
        (acc, { articles }) => [...acc, ...articles],
        []
      )
    },
    getDefaultTake() {
      return DEFAULT_TAKE
    },
    isInViewport(position) {
      return position < ARTICLE_SLOT_COUNT_IN_VIEWPORT
    }
  }
}
</script>

<style lang="scss" scoped>
.category-news__wrapper {
  .category-news__title {
    @include mobile {
      margin-bottom: 20px;
      padding-right: 50px; /* limit text to avoid overlap with JoinTelegram widget */
    }
  }

  .category-news__title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .category-news__join-telegram {
    @include positionJoinTelegramIcon;
  }

  .category-news__card {
    margin-top: $gap-between-page-title-and-content-card;

    @include mobile {
      margin-top: 0;
    }
  }

  .category-news__article {
    margin-bottom: 20px;
  }

  /deep/ .article-list__item-wrapper:last-child .category-news__article {
    margin-bottom: 0;
  }

  .category-news__banner {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .category-news__video-banner {
    margin: 30px 0 20px 0;

    @include mobile {
      margin-bottom: 30px;
    }
  }

  .category-news__video {
    margin: 40px 0;

    @include mobile {
      margin: 20px 0;
    }
  }

  /deep/ .category-news__see-more-less {
    margin-top: 20px;
  }
}
</style>
