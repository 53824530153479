var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-news__wrapper"},[_c('a-lazy-hydrate',{attrs:{"when-visible":""}},[_c('a-visibility',{attrs:{"hide":"","to":_vm.$breakpoint.desktopSm}},[_c('a-breadcrumbs',{staticClass:"click-spam-left-padding",attrs:{"settings":_vm.breadcrumbsSettings,"prettify-names":false}})],1)],1),_vm._v(" "),_c('a-visibility',{attrs:{"hide":"","on":[_vm.$breakpoint.mobile]}},[_c('h1',{staticClass:"category-news__title click-spam-left-padding title"},[_vm._v("\n      "+_vm._s(_vm.categoryName)+"\n    ")])]),_vm._v(" "),_c('a-card',{staticClass:"category-news__card skip-mobile-paddings"},[_c('a-visibility',{attrs:{"show":"","on":[_vm.$breakpoint.mobile]}},[_c('h1',{staticClass:"category-news__title click-spam-left-padding"},[_vm._v("\n        "+_vm._s(_vm.categoryName)+"\n      ")])]),_vm._v(" "),_c('a-visibility',{attrs:{"show":"","on":[_vm.$breakpoint.mobile]}},[_c('a-join-telegram',{staticClass:"category-news__join-telegram"})],1),_vm._v(" "),_c('a-article-list',{staticClass:"category-news__article-list",attrs:{"articles":_vm.articles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var article = ref.article;
var position = ref.position;
return [_c('a-lazy-hydrate',{attrs:{"when-visible":""}},[_c('a-article-slot',_vm._b({directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.getArticleVisibilityOptions(position)),expression:"getArticleVisibilityOptions(position)"}],staticClass:"category-news__article",attrs:{"hide-category":!article.company,"image-viewport-optimization":_vm.isInViewport(position)}},'a-article-slot',article,false))],1)]}},{key:"banner",fn:function(ref){
var position = ref.position;
return [(_vm.isVideoBannerVisible(position))?_c('a-video-banner',{staticClass:"category-news__video-banner",attrs:{"video-banner-type":_vm.VIDEO_BANNER_TYPE.CATEGORY}}):(_vm.isBannerVisible(position))?_c('a-banner',{staticClass:"category-news__banner",attrs:{"banner-settings":_vm.getBannerSettings(position),"use-random-banner-id":""}}):_vm._e()]}},{key:"video",fn:function(ref){
var position = ref.position;
return [(_vm.isVideoVisible(position))?_c('a-video-slot',{staticClass:"category-news__video",attrs:{"video-settings":_vm.videoSettings}}):_vm._e()]}}])}),_vm._v(" "),_c('a-see-more-less',{staticClass:"category-news__see-more-less",attrs:{"slot":"bottom-section","page":_vm.pagination.page,"pages-count":_vm.pagesCount,"is-expanded":false,"in-progress":_vm.inProgress},on:{"input":_vm.getNextArticleChunk},slot:"bottom-section"})],1),_vm._v(" "),_c('a-url-pagination',{attrs:{"pagination":_vm.pagination,"item-count":_vm.articles.length,"initial-page":_vm.initialPage,"item-selector":_vm.ARTICLE_SLOT_SELECTOR,"pages-count":_vm.pagesCount,"default-take-fn":_vm.getDefaultTake,"non-paginated-url":_vm.canonicalUrlWithoutPagination},on:{"update:pagination":function($event){_vm.pagination=$event}}}),_vm._v(" "),_c('a-pagination-links-for-google',{attrs:{"page":_vm.pagination.page,"pages-count":_vm.pagesCount,"non-paginated-url":_vm.canonicalUrlWithoutPagination}}),_vm._v(" "),_c('a-refresh-listener',{attrs:{"articles":_vm.articles,"feed-refresh-in-progress":_vm.feedRefreshInProgress,"slot-refresh-in-progress":_vm.slotRefreshInProgress,"fetch-data-fn":_vm.fetchLoadedArticles,"target-category-slug":_vm.categorySlug,"settings":_vm.$options.consts.AUTO_REFRESH_SETTINGS.CATEGORY_PAGE},on:{"update:articles":function($event){_vm.articles=$event},"update:feedRefreshInProgress":function($event){_vm.feedRefreshInProgress=$event},"update:feed-refresh-in-progress":function($event){_vm.feedRefreshInProgress=$event},"update:slotRefreshInProgress":function($event){_vm.slotRefreshInProgress=$event},"update:slot-refresh-in-progress":function($event){_vm.slotRefreshInProgress=$event},"feed-refreshed":_vm.$_disqusCommentCount_updateCommentCounts}}),_vm._v(" "),(_vm.isStickyToBottomBannerVisible)?_c('a-sticky-banner',{attrs:{"sticky-position":_vm.STICKY_BANNER_POSITION.BOTTOM,"banner-settings":_vm.BANNER_SETTINGS.CATEGORY.STICKY_TO_BOTTOM}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }